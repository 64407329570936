footer {
    font-weight: 400;
    font-size: 14px;
    padding: 70px 0 40px 0;
    background: var(--footerBg);
    transition: background 200ms linear;
    p {
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        color: var(--greyColor);
      }
    a {
        padding: 0 8px;
    }
  }

  .partners {

    a{
      position: relative;
      &:hover {
        &::after {
          opacity: 0.95;
          backdrop-filter: blur(10px);
          clip: initial;
        }
      }
      &::after {
        content: attr(data-parent);
        color: #FAFAFA;
        text-decoration: none;
        position: absolute;
        bottom: calc(100% + 20px);
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        clip: rect(0,0,0,0);
        transition: all 0.3s;
        background-color: var(--greyColor);
        padding: 10px;
        border-radius: 5px;
        font-weight: 400;
        z-index: 2;
      }

      @media screen and (max-width: 475px) { 
        &:first-child {
          &::after {
            left: 125%;
          }
        }
        &:nth-last-child(2),
        &:last-child {
          &::after {
            left: -35%;
          }
        }
      }
    }

    hr {
      margin: 10px auto 15px;
      max-width: 90%;
    }

    img {
      height: 48px;
      filter: grayscale(100%);
      transition: all 0.2s;
      &:hover {
        filter: grayscale(0);
      }
    }
  }

  .download-inner {
    a {
      &:hover {
        .download-svg path {
          fill: var(--firmGreenColor);
        }
      }
    }
  }

  .description{
    margin-top: 20px;
  }

  .prefooter{
    background: var(--footerBg);
    transition: background 200ms linear;
    position: relative;
    z-index: 2;
    

    a{
       font-weight: 500;
       font-size: 12px;  
       color: var(--themeColor);
    }

    ul{
       margin: 0;
       padding: 0;
       li{
        list-style: none;
        padding: 0;
        a{
            color: var(--greyColor);
            line-height: 10px;
        }
      }
    }

    h3{
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;    
        color: var(--greyColor);
        margin-bottom: 5px;
    }
  }
 
  h3.electro {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: var(--firmGreenColor);
    margin: 8px 0;
    margin-bottom: 15px;

    &:after{
        content: "";
        height: 2px;
        background: var(--firmGreenColor);
        display: block;
        margin-top: 8px;    
    }
  }
  
  h3.gaz{
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: var(--firmYellowColor);
    margin: 8px 0;
    margin-bottom: 15px;

    &:after{
        content: "";
        height: 2px;
        background: var(--firmYellowColor);
        display: block;
        margin-top: 8px;    
    }

    h3{
        color: var(--firmYellowColor);
        margin-bottom: 5px;
    }
  }

  .btn-grin{
    background: var(--firmGreenColor);
    border-radius: 3px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    color: #FAFAFA;    
    display: block;
    padding: 5px 0;
  }
  .btn-yellow{
    background: var(--firmYellowColor);
    border-radius: 3px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    color: #FAFAFA;    
    display: block;
    padding: 5px 0;
  }

  .borderline{
    margin-bottom: 25px;
    &::after, &::before {
        content: "";
        height: 1px;
        display: block;
        background: var(--greyColor);
        margin: 10px calc(var(--bs-gutter-x) * 0.5);
        width: calc(100% - var(--bs-gutter-x) * 0.5);
    }
  }
  .footer-head{
    margin-top: 60px;
  }
  .third:after{
    content: "";
    height: 1px;
    display: block;
    background: var(--greyColor);  
    margin-top: 10px;
  }
  .menu-nav .col-4 a{
    color: var(--greyColor);
    margin-bottom: 20px;
    display: block;
  }

  .chat {
    &__show {
      position: fixed;
      right: 70px;
      bottom: 200px;
      display: flex;
      gap: 10px;
      flex-direction: row-reverse;
      z-index: -1;
      opacity: 0;
      transition: all 0.3s;

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
    }

    &__close {
      cursor: pointer;
      padding-left: 20px;
      img {
        transition: all 0.3s;
        &:hover {
          filter: brightness(0.5);
        }
      }
    }

    &__line {
      padding-right: 20px;
      border-right: 1px solid var(--greyColor);
    }

    &__phone img,
    &__mail img{
      cursor: pointer;
      -webkit-animation: pulsing 2s infinite;
      animation: pulsing 2s infinite;
    }
  }

  .chat__show.active {
    opacity: 1;
    z-index: 40;
  }

  .footer-chat {
    &__logo{
      position: fixed;
      right: 50px;
      bottom: 100px;
      border-radius: 100%;
      cursor: pointer;
      // background-color: #77bf43;
      -webkit-animation: pulsing 2s infinite;
      animation: pulsing 2s infinite;
      z-index: 50;
      opacity: 1;
      transition: opacity 0.3s;
  
      img {
          width: 70px;
          height: 70px;
          object-fit: cover;
          -webkit-animation: rotation 1s infinite;
          animation: rotation 1s infinite;
      }
    }
  }

  .footer-chat a.hide {
    opacity: 0;
  }

  .footer-modal {
    &__phones {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      gap: 30px;
      // color: #dee2e6;

      h5 {
        margin-bottom: -15px;
      }
    }
    .modal-dialog {
      max-width: 300px !important;
    }
    .modal-body {
      padding: 10px 10px 15px 10px;
    }
    .modal-header {
      padding: 8px;
    }

    .phone-block {
      border: 1px solid var(--firmGreenColor);
      border-radius: 4px;
      padding: 30px 60px;
      a:hover {
        color: var(--firmGreenColor);
      }
    }
  }

  @keyframes pulsing {
    0% {
      -webkit-transform: scale(0.85, 0.85);
      transform: scale(0.85, 0.85);
    }
    50% {
      -webkit-transform: scale(1.0, 1.0);
      transform: scale(1.0, 1.0);
    }
    100% {
      -webkit-transform: scale(0.85, 0.85);
      transform: scale(0.85, 0.85);
    }
  }

  @keyframes rotation {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    50% {
      -webkit-transform: rotate(80grad);
      transform: rotate(80grad);
    }
    100% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
  }

  @media screen and (max-width: 1400px) { 
    footer a  {
      padding: 0 6px;
    }
  }

  @media screen and (max-width: 1200px) { 
    .foot-info,
    .foot-recv {
      width: 100%;
      margin-bottom: 0 !important;
    }
    
    .foot-recv {
      margin-top: 0 !important;
    }
    .partners {
      img{
        margin-bottom: 5px;
      }
    }
    .download-app {
      p {
        line-height: 30px;
      }
    }
    footer a  {
      padding: 0 4px;
    }

    .download-inner {
      position: relative;
      left: 30px;
      a {
        padding: 3px;
      }
    }
  }


  @media screen and (max-width: 992px)  {
    .download-inner {
      width: 12%;
    }
  }

  @media screen and (max-width: 767px) { 
    .download-app {
       max-width: 70%;
       p {
        line-height: 18px;
      }
    }
    .download-inner {
      max-width: 30%;
      margin-bottom: 15px;
      min-width: 95px;
      text-align: right !important;
    }
    .theme-inner {
      max-width: 25%;
    }
  }

  @media screen and (max-width: 475px) { 
    .download-app {
      max-width: 30%;
    }
    .footer-chat {
      &__logo {
        right: 10px;
        bottom: 10px;
        img {
          width: 65px;
          height: 65px;
        }
      }
    }
    .chat {
      &__show {
        bottom: 100px;
      }
    }
  }