/*================================================
  Global CSS
=================================================*/

// @import-normalize; 
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800&display=swap');
@import "~bootstrap/scss/bootstrap";

html[data-theme='light'] {
  --backgroundImg: url("/img/bg-w.jpg");
  --backgroundLogo: url('/img/logo-w.svg');
  --backgroundLamp: url('/img/bg-lamp.svg');
  --themeColor: #3E4042;
  --firmColor: #FFFFFF;
  --footerBg: #FAFAFA;
  --arrowColor: #FFFFFF;
}

html[data-theme='dark'] {
  --backgroundImg: url("/img/bg-b.jpg");
  --backgroundLogo: url('/img/logo-b.svg');
  --backgroundLamp: url('/img/bg-lamp-white.svg');
  --themeColor: #ffff;
  --firmColor: #56595C;
  --footerBg: #2F3133;
  --arrowColor: #83898e;
}

:root {
	--fontFamily: 'Roboto';
	--montFontFamily: 'Montserrat';
  --firmGreenColor: #77C043;
  --firmYellowColor: #FFC736;
	--lightGreenColor: #9BE650;
  --darkGreenColor: #37591F;
  --greyColor: #858A8F;
	--fontColor: var(--themeColor);
	--fontSize: 16px;
  --lineHeight: 24px;
}

#root {
  overflow-x: hidden;
}

body {
	margin: 0;
	padding: 0;
	line-height: var(--lineHeight);
  color: var(--fontColor);
  background: var(--backgroundImg);
  transition: background 200ms linear;
	font: {
		size: var(--fontSize);
		family: var(--fontFamily);
	}
  }
  
  // p, span {
  //   font-size: var(--fontSize);
  // }

  a {
    color: inherit;
    font-family: var(--fontFamily);
    font-size: var(--fontSize);
    text-decoration: underline;
    text-decoration-color: initial;
    font-weight: 600;

    &:hover {
      // text-decoration: underline;
      // text-decoration-color: initial;
      font-weight: 800 !important;
    }
  }

  h1,h2,h3 {
    margin: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
  .logo {
    background: var(--backgroundLogo);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 110px;
    height: 48px;
    transition: background 200ms linear;
  }
  
  .line {
    margin-bottom: 10px;
    width: 139px;
    height: 8px;
    background-image: linear-gradient(#56595C,#56595C), linear-gradient(to left top,transparent 50%,#56595C 0%);
    background-repeat: no-repeat;
    background-size: 90px 90px;
    background-position: 0%, 90px;
  
  &:before{
    content: "";
    margin:0;
    display: block;
    width: 59px;
    height:8px;
    background-image: linear-gradient(var(--firmGreenColor),var(--firmGreenColor)), linear-gradient(to left top,transparent 50%,var(--firmGreenColor) 0%);
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: 0%, 30px;
    }
  }

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 50px 0;
    min-height: calc(100vh - 143px);

    .text-success {
      color: var(--firmGreenColor) !important;
    }
  }

  
   /*================================================
    SwitchTheme styles 
 =================================================*/

  .tumbler-wrapper {
    margin-left: auto;
    width: 80px;
    height: 35px;
    background-color: #56595C;
    border: darken(#aae2f1, 40);
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px;
    cursor: pointer;
    display: flex;
    position: relative;
    user-select: none;
  
      svg {
        width: 15px;
        height: 15px;
      }
  }

  
.tumbler {
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #fff;
  transition: transform .5s, background-color .5s;
  will-change: transform;
}

.tumbler.night-mode {
  transform: translateX(calc(100% + 20px));
}

.mont-block {
    background-image: url('../img/mont-white-bg.png');
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
}

 /*================================================
    Title styles 
 =================================================*/
  
  .heading{
    margin-bottom: 20px; 
    h2 {
    font-family: var(--montFontFamily);
    font-style: normal;
    font-weight: 800;
    max-width: 900px;
    font-size: 96px;
    line-height: 103px;     
    color: var(--fontColor);
  }
   h3 {
     font-family: var(--montFontFamily);
     font-style: normal;
     font-weight: 800;
     max-width: 900px;
     font-size: 48px;
     line-height: 56px;
     margin-bottom: 12px;
   }
 }

  .type{
    font-weight: 500;
    max-width: 900px;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 120px;

    img {
      margin-right: 12px;
    }
  }

   /*================================================
    No-page styles 
 =================================================*/
 .no-page {
    min-height: calc(100vh - 25px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 }
 .error-title {
    margin-bottom: 60px;
  .type {
    margin-bottom: 5px;
    font-size: 32px;
    line-height: 40px;
  }
  a {
    font-size: 32px;
    line-height: 40px;
    &:hover {
      color: var(--greyColor);
    }
  }
 }

 .error-title-sub {
  h3 {
    font-size: 32px;
    line-height: 40px;
    max-width: 100%;
  }
  .type {
    margin-bottom: 40px;
  }
 }

 .no-page__inner ~ footer {
  background-color: transparent;
  padding: 10px 0;
 }

  /*================================================
    Modal styles 
 =================================================*/

.modal-body {
    display: flex;
    gap: 10px;
    color: #56595C;
  }

.modal-dialog { 
    max-width:  700px !important; 
  }

.modal__content {
    padding: 10px;
  }

.pad {
    min-height: 100vh;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
}
 

  @media screen and (max-width: 991px) {
    .heading{
      h2 {
      font-size: 72px;
      line-height: 80px;     
      }
    }
  }

  @media screen and (max-width: 768px) {
    .heading{
      h2 {
        font-size: 46px;
        line-height: 53px;     
      }
      h3 {
        font-size: 38px;
        line-height: 46px;
      }
    }

    .type{
      font-size: 16px;
      margin-bottom: 60px;
    }
  }

  @media screen and (max-width: 510px) {
    .heading{
      h2 {
      font-size: 38px;
      line-height: 48px;     
      }
      h3 {
        font-size: 36px;
        line-height: 44px;
      }
    }
    .logo {
      background-size: contain;
      height: 40px;
    }
  }

  @media screen and (max-width: 410px) { 
    .heading{
      h2 {
      font-size: 33px;
      line-height: 46px;     
      }
      h3 {
        font-size: 27px;
        line-height: 36px;
      }
    }
    .type{
      margin-bottom: 30px;
    }
  }
